<template>
  <div>
    <Hero />
    <Picture />
    <Intro />
    <Solutions />
    <Contact   />
  </div>
</template>

<script>
import Hero from "@/components/Home/Hero";
import Intro from "@/components/Home/Intro";
import Picture from "@/components/Home/Picture";
import Solutions from "@/components/Home/Solutions";
import Contact from "@/components/Home/Contact";

export default {
  name: "Home",
  components: { Hero, Intro, Solutions, Contact, Picture },
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
