<template>
  <section>
    <img :src="picture" class="w-full h-auto" />
  </section>
</template>

<script>
import picture from "@/assets/Startseite_01.png";

export default {
  name: "Picture",
  setup() {
    return {
      picture,
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
