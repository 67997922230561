<template>
  <div>
    <Header class="w-full fixed z-50" />
    <router-view class="z-10" />
    <Footer class="z-50" />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  mounted() {
    console.log(
      "%cWir suchen dich!!",
      "font-weight: bold; font-size: 30px;color: red; text-shadow: 1px 1px 0 rgb(217,31,38) , 2px 2px 0 rgb(226,91,14) , 4px 4px 0 rgb(245,221,8) , 7px 7px 0 rgb(5,148,68) , 11px 11px 0 rgb(2,135,206) , 13px 13px 0 rgb(4,77,145) , 15px 15px 0 rgb(42,21,113)"
    );
    console.log("%cBewirb dich jetzt", "color: white; font-size: 20px");
    console.log("https://zw-systems.de/career");
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap");

html,
body {
  font-family: "Raleway", sans-serif;
}

#app {
  font-family: "Raleway", sans-serif;
}
</style>
