<template>
  <section class="py-2 px-4 lg:px-10 bg-dunkelgruen border-b-2 border-white">
    <div class="flex w-full    lg:mt-0 justify-center items-center">
      <h2 class="text-5xl font-bold text-white my-20" >
        Data. Expert. Marketing.
      </h2>
    </div>
  </section>
</template>

<script>
export default {
  name: "Intro",
  setup() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
