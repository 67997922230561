<template>
  <section class="py-2 px-4 lg:px-10 bg-anthrazit border-b-2 border-white">
    <nav class="relative flex justify-between items-center lg:items-baseline ">
      <button class="lg:hidden p-2 navbar-burger">
        <svg
          class="w-10 h-3"
          width="39"
          height="13"
          viewBox="0 0 39 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="39" height="2" rx="1" fill="#C4C4C4"></rect>
          <rect
            x="19"
            y="11"
            width="20"
            height="2"
            rx="1"
            fill="#C4C4C4"
          ></rect>
        </svg>
      </button>
      <div class="hidden lg:flex w-2/6 justify-evenly">
        <div class=" flex   space-x-1 items-center cursor-pointer">
          <img
            v-show="$route.path === '/'"
            :src="activeHeaderLogo"
            class="w-3 h-3   "
          />
          <a
            class="  mr-10 text-sm text-white hover:text-gray-50 border-transparent  uppercase hover:border-gray-600  "
            @click="openPage('Home')"
            v-bind:class="{ 'font-bold ': $route.path === '/' }"
          >
            Home</a
          >
        </div>
        <div class=" flex   space-x-1 items-center cursor-pointer">
          <img
            v-show="$route.path === '/about-us'"
            :src="activeHeaderLogo"
            class="w-3 h-3   "
          />
          <a
            class="  mr-10 text-sm text-white hover:text-gray-50   border-transparent  uppercase hover:border-gray-600  "
            @click="openPage('AboutUs')"
            v-bind:class="{ 'font-bold ': $route.path === '/about-us' }"
          >
            Über uns</a
          >
        </div>
        <div class=" flex   space-x-1 items-center cursor-pointer">
          <img
            v-show="$route.path === '/service'"
            :src="activeHeaderLogo"
            class="w-3 h-3   "
          />
          <a
            class="  mr-10 text-sm text-white hover:text-gray-50   border-transparent  uppercase hover:border-gray-600  "
            @click="openPage('Service')"
            v-bind:class="{ 'font-bold ': $route.path === '/service' }"
          >
            Leistungen</a
          >
        </div>
      </div>
      <div class="">
        <div
          class="text-2xl text-white font-bold  cursor-pointer"
          @click="openPage('Home')"
        >
          <img :src="logo" alt="zw-systems-logo" class="h-14" />
        </div>
      </div>
      <div class="hidden lg:flex w-2/6 justify-evenly">
        <!-- <div class=" flex   space-x-1 items-center cursor-pointer">
          <img
            v-show="$route.path === '/team'"
            :src="activeHeaderLogo"
            class="w-3 h-3   "
          />
          <a
            class="  mr-10 text-sm text-white hover:text-gray-50   border-transparent  uppercase hover:border-gray-600  "
            @click="openPage('Team')"
            v-bind:class="{ 'font-bold ': $route.path === '/team' }"
          >
            Team</a
          >
        </div> -->
        <div class=" flex   space-x-1 items-center cursor-pointer">
          <img
            v-show="$route.path === '/contact'"
            :src="activeHeaderLogo"
            class="w-3 h-3   "
          />
          <a
            class="  mr-10 text-sm text-white hover:text-gray-50  border-transparent  uppercase hover:border-gray-600  "
            @click="openPage('ContactView')"
            v-bind:class="{ 'font-bold ': $route.path === '/contact' }"
          >
            Kontakt</a
          >
        </div>
        <div class=" flex   space-x-1 items-center cursor-pointer">
          <img
            v-show="$route.path === '/career'"
            :src="activeHeaderLogo"
            class="w-3 h-3   "
          />
          <a
            class="  mr-10 text-sm text-white hover:text-gray-50   border-transparent  uppercase hover:border-gray-600  "
            @click="openPage('Career')"
            v-bind:class="{ 'font-bold ': $route.path === '/career' }"
          >
            Jobs</a
          >
        </div>
      </div>
    </nav>
    <div class="hidden navbar-menu relative z-50 ">
      <div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80"></div>
      <nav
        class="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-8 bg-anthrazit overflow-y-auto"
      >
        <div class="flex items-center mb-16 pr-6">
          <div
            class="ml-10 text-2xl text-gray-800 font-bold cursor-pointer"
            @click="openPage('Home')"
          >
            <img :src="logo" alt="zw-systems-logo" width="250" height="150" />
          </div>
        </div>
        <div>
          <ul>
            <li class="mb-1 px-10">
              <a
                class="block pl-8 py-4 text-xl text-white hover:bg-blueGray-50 uppercase rounded-xl"
                v-bind:class="{ 'font-bold': $route.path === '/' }"
                @click="openPage('Home')"
                >Home</a
              >
            </li>
            <li class="mb-1 px-10">
              <a
                class="block pl-8 py-4 text-xl text-white hover:bg-blueGray-50  uppercase rounded-xl"
                v-bind:class="{ 'font-bold': $route.path === '/about-us' }"
                @click="openPage('AboutUs')"
                >Über uns</a
              >
            </li>
            <li class="mb-1 px-10">
              <a
                class="block pl-8 py-4 text-xl text-white hover:bg-blueGray-50 uppercase rounded-xl"
                v-bind:class="{ 'font-bold': $route.path === '/service' }"
                @click="openPage('Service')"
                >Leistungen</a
              >
            </li>
            <!-- <li class="mb-1 px-10">
              <a
                class="block pl-8 py-4 text-xl text-white hover:bg-blueGray-50 uppercase rounded-xl"
                v-bind:class="{ 'font-bold': $route.path === '/team' }"
                @click="openPage('Team')"
                >Team</a
              >
            </li> -->
            <li class="mb-1 px-10">
              <a
                class="block pl-8 py-4 text-xl text-white hover:bg-blueGray-50   uppercase rounded-xl"
                v-bind:class="{ 'font-bold': $route.path === '/contact' }"
                @click="openPage('ContactView')"
                >Kontakt</a
              >
            </li>
            <li class="mb-1 px-10">
              <a
                class="block pl-8 py-4 text-xl text-white hover:bg-blueGray-50     uppercase rounded-xl"
                v-bind:class="{ 'font-bold': $route.path === '/career' }"
                @click="openPage('Career')"
                >Jobs</a
              >
            </li>
            <li class="mb-1 px-10">
              <a
                class="block pl-8 py-4 text-xl text-white hover:bg-blueGray-50   uppercase rounded-xl"
                v-bind:class="{ 'font-bold': $route.path === '/imprint' }"
                @click="openPage('Imprint')"
                >Impressum</a
              >
            </li>
            <li class="mb-1 px-10">
              <a
                class="block pl-8 py-4 text-xl text-white hover:bg-blueGray-50  uppercase rounded-xl"
                v-bind:class="{ 'font-bold': $route.path === '/privacy' }"
                @click="openPage('Privacy')"
                >Datenschutz</a
              >
            </li>
          </ul>
        </div>
        <div class="mt-auto px-10">
          <p class="mt-6 mb-4 text-lg text-white text-center">
            <span>{{ year }} © ZW-Systems</span>
          </p>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
import logo from "@/assets/Logo.svg";
import activeHeaderLogo from "@/assets/activeHeaderLogo.png";

export default {
  name: "Header",
  setup() {
    return {
      logo,
      activeHeaderLogo,
    };
  },
  data: () => ({
    year: new Date().getFullYear(),
  }),
  methods: {
    openPage(page) {
      this.$router.push({
        name: page,
      });
    },
  },

  mounted() {
    document.addEventListener("DOMContentLoaded", function() {
      // open
      const burger = document.querySelectorAll(".navbar-burger");
      const menu = document.querySelectorAll(".navbar-menu");

      if (burger.length && menu.length) {
        for (var i = 0; i < burger.length; i++) {
          burger[i].addEventListener("click", function() {
            for (var j = 0; j < menu.length; j++) {
              menu[j].classList.toggle("hidden");
            }
          });
        }
      }

      // close
      const close = document.querySelectorAll(".navbar-menu");
      const backdrop = document.querySelectorAll(".navbar-backdrop");

      if (close.length) {
        for (var i = 0; i < close.length; i++) {
          close[i].addEventListener("click", function() {
            for (var j = 0; j < menu.length; j++) {
              menu[j].classList.toggle("hidden");
            }
          });
        }
      }

      if (backdrop.length) {
        for (var i = 0; i < backdrop.length; i++) {
          backdrop[i].addEventListener("click", function() {
            for (var j = 0; j < menu.length; j++) {
              menu[j].classList.toggle("hidden");
            }
          });
        }
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
