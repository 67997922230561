<template>
  <section
    class="py-10  lg:py-32  border-b-2 border-white  bg-top bg-cover bg-no-repeat"
    v-bind:style="{ backgroundImage: 'url(' + background + ')' }"
  >
    <div class="relative container px-4 pt-12   mx-auto">
      <div class="flex w-full   items-center -mx-4  ">
        <div class="w-full  px-4   lg:mb-0">
          <h2
            class=" mt-12   text-4xl lg:text-5xl text-white font-bold font-heading"
          >
            Kontaktieren Sie uns!
          </h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import background from "@/assets/Kontakt_01.png";

export default {
  name: "Hero",
  setup() {
    return {
      background,
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
