<template>
  <section class="py-24 px-4 lg:px-40 bg-dunkelgruen border-b-2 border-white">
    <h2 class="text-white font-bold text-3xl mb-10">Schnell per Formular</h2>
    <form @submit.prevent novalidate="true">
      <div
        class="flex flex-col lg:flex-row justify-between space-y-10 lg:space-y-0 lg:space-x-10 "
      >
        <div class="w-full ">
          <p class="text-white font-semibold mb-2">Vor- und Nachname&#42;</p>
          <input
            v-model="name"
            type="text"
            class="w-full p-3 font-semibold h-10"
          />
        </div>
        <div class="w-full  ">
          <p class="text-white font-semibold mb-2">Mail-Adresse&#42;</p>
          <input
            v-model="email"
            type="email"
            class="w-full p-3 font-semibold  h-10"
          />
        </div>
      </div>
      <div class="w-full mt-8  ">
        <p class="text-white font-semibold mb-2 ">Nachricht&#42;</p>
        <textarea
          v-model="message"
          rows="8"
          class="w-full  p-3 font-semibold"
        />
      </div>
      <div v-if="errors.length > 0" class="bg-red-400 p-3 rounded mt-4 w-full">
        <p
          v-for="error in errors"
          :key="error"
          class="text-white font-semibold"
        >
          {{ error }}
        </p>
      </div>
      <button
        type="submit"
        @click="checkForm"
        class="mt-10 bg-hellblau p-3 text-dunkelblau w-full lg:w-52 rounded-lg self-center font-bold text-center cursor-pointer"
      >
        Abschicken
      </button>
    </form>

    <div v-if="showSuccessMessage" class="bg-green-200 mt-6 rounded p-2">
      <p class="">Danke! Deine Nachricht ist bei uns eingegangen.</p>
    </div>
  </section>
</template>

<script>
const axios = require("axios");

export default {
  name: "ContactForm",
  setup() {
    return {};
  },
  data() {
    return {
      errors: [],
      name: null,
      email: null,
      message: null,
      showSuccessMessage: false,
    };
  },
  components: {},
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?render=" +
        process.env.VUE_APP_RECAPTCHA_SITE_KEY
    );
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    checkForm() {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Bitte tragen Sie Ihren Namen ein!");
      }
      if (!this.email) {
        this.errors.push("Bitte tragen Sie eine E-Mail Adresse ein!");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Bitte tragen Sie eine gültige E-Mail Adresse ein!");
      }

      if (!this.message) {
        this.errors.push("Bitte tragen Sie eine Nachricht ein!");
      }

      if (!this.errors.length) {
        this.sendMessage();
      }
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendMessage() {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {
            action: "submit",
          })
          .then((token) => {
            const data = {
              token: token,
              formData: {
                receiverName: this.name,
                receiverMail: this.email,
                receiverMessage: this.message,
                subject: "Ihre Nachricht an ZW-Systems GmbH",
                htmlBody:
                  "Vielen Dank, dass Sie uns kontaktiert haben. <br> Wir melden uns kurzfristig bei Ihnen. <br><br> Ihr Team von <br><br> ZW Systems GmbH",
              },
            };
            axios
              .post(process.env.VUE_APP_BACKEND_URL + "contactForm", data)
              .then((res) => {
                if (res.status === 200) {
                  this.showSuccessMessage = true;
                }
              });
          });
      });
    },
  },
};
</script>
<style scoped></style>
