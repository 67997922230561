<template>
  <section class="py-10   px-4 lg:px-10 bg-dunkelblau border-b-2 border-white">
    <div
      class="flex flex-col  md:flex-row md:justify-center  items-center  space-y-20  w-full   mb-2 "
    >
      <div class="w-full md:w-1/2  flex justify-center   ">
        <img :src="hello" class="w-80 h-auto  -mb-12 " />
      </div>
      <div class="space-y-4 w-full md:w-1/2 px-4">
        <div
          @click="$router.push({ path: 'contact' })"
          class="mt-10 bg-hellblau p-3 text-dunkelblau w-full lg:w-72 rounded-lg  hover:bg-gray-400  font-bold text-center cursor-pointer"
        >
          Kontaktieren Sie uns!
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import hello from "@/assets/hello.png";

export default {
  name: "Contact",
  setup() {
    return {
      hello,
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
