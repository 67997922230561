<template>
  <section class="py-20 px-4  lg:px-48 bg-hellblau border-b-2 border-white">
    <div
      class="flex flex-col items-center lg:flex-row w-full justify-between   "
    >
      <div class="flex flex-col   lg:w-3/5 my-0   lg:mt-0 space-y-10 mr-10 ">
        <h2 class="text-4xl font-bold text-anthrazit">
          ZW Systems - Systembedingt nur die besten Lösungen.
        </h2>
        <p class="text-lg   text-anthrazit">
          Die Digitalisierung ist so wichtig wie nie. Die heutigen
          Geschäftsmodelle und Produkte verlangen flexible, perfekt auf die
          Anforderungen und Firmenstruktur abgestimmte Lösungen – egal ob
          Onlineshop, Website, Applikationen oder auch individualisierte
          Software.
        </p>
        <p class="text-lg  text-anthrazit">
          Unsere DNA ist digital und liefert mit unternehmerischem Background
          und technischem Produktverständnis von ganzheitlicher Energie- und
          Systemtechnik die ideale Voraussetzung für eine zielgerichtete, auf
          Ihre Anforderungen abgestimmte Technologie.
        </p>
      </div>
      <div
        class="flex w-full mt-10 lg:mt-0 lg:w-2/5 justify-center items-center"
      >
        <p class="text-6xl lg:text-8xl font-thin text-white element"></p>
        <p class="text-6xl lg:text-8xl font-thin text-white element">gen</p>
      </div>
    </div>
  </section>
</template>

<script>
import Typed from "typed.js";

export default {
  name: "Solutions",
  setup() {
    return {};
  },
  components: {},
  mounted() {
    var options = {
      strings: ["Fra", "Lösun"],
      typeSpeed: 200,
      backSpeed: 200,

      loop: true,
      showCursor: false,
    };

    var typed = new Typed(".element", options);
  },
  methods: {},
};
</script>
<style scoped></style>
