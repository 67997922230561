<template>
  <section class="py-10  lg:py-6 bg-dunkelblau border-b-2 border-white">
    <div class="relative container   pt-12   mx-auto">
      <div class="flex w-full px-4 lg:px-32  items-center lg:-mx-4 mb-0 ">
        <div class="w-full lg:w-3/5  mb-16 lg:mb-0 md:mr-10">
          <h2
            class="max-w-2xl mt-20  mb-14 text-4xl lg:text-4xl text-white font-bold font-heading"
          >
            ZW Systems. <br>Unsere DNA ist digital.
          </h2>
          <p class="mb-6 w-4/5 font-semibold text-lg text-gray-100">
            Wir heben Ihr Unternehmen auf ein neues Level.
          </p>
          <p
            class="mb-12 lg:mb-16 2xl:mb-24 text-lg w-full md:w-3/4 text-gray-100"
          >
            Digitale Transformation erhöht die Reaktionsgeschwindigkeit von
            Unternehmen und die Effizienz ihrer Maßnahmen.
          </p>
        </div>
        <div class="hidden lg:inline-block lg:w-2/5 px-4 mb-16 ">
          <div class="flex flex-col justify-center items-center ">
            <div class="particles w-full  bg-dunkelblau z-40">
              <div id="particles-js"></div>
            </div>
            <div class="reveal text-6xl text-white  -mt-40">
              <p>Hello World</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import particlesJS from "@/assets/particles";

export default {
  name: "Hero",
  components: {},
  mounted() {
    this.initParticles();
  },
  methods: {
    initParticles() {
      window.particlesJS("particles-js", {
        particles: {
          number: {
            value: 126,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#1b1e34",
          },
          shape: {
            type: ["image", "image2"],
            stroke: {
              width: 0,
              color: "#000",
            },
            polygon: {
              nb_sides: 4,
            },
            image: {
              src:
                "https://www.google.de/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
              width: 35,
              height: 35,
            },
          },
          opacity: {
            value: 0.3,
            random: true,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 10,
            random: false,
            anim: {
              enable: false,
              speed: 10,
              size_min: 40,
              sync: false,
            },
          },
          line_linked: {
            enable: false,
            distance: 80,
            color: "#ffffff",
            opacity: 1,
            width: 0.5,
          },
          move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "repulse",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 100,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    },
  },
};
</script>
<style scoped>
#particles-js {
  z-index: 9999;
}

.particles {
  -webkit-transition: opacity 0.8s ease-in;
  -moz-transition: opacity 0.8s ease-in;
  -ms-transition: opacity 0.8s ease-in;
  -o-transition: opacity 0.8s ease-in;
  transition: opacity 0.8s ease-in;
}

.particles:hover {
  zoom: 1;
  filter: alpha(opacity=0);
  opacity: 0;
}
.particles:hover .reveal {
  zoom: 1;
  filter: alpha(opacity=100);
  opacity: 100;
}
</style>
