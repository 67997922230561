<template>
  <section class="py-2 px-4 lg:px-10 bg-anthrazit border-b-2 border-white">
    <nav
      class="relative flex flex-col lg:flex-row justify-between items-center lg:items-start "
    >
      <div
        class=" flex flex-col lg:flex-row space-y-4 lg:space-y-0 mb-10 text-center "
      >
        <div
          class="inline-block lg:mr-10  text-sm text-white hover:text-gray-50  border-transparent border-b-2 uppercase hover:border-gray-600 cursor-pointer "
          @click="$router.push({ path: 'imprint' })"
        >
          Impressum
        </div>
        <div
          class="inline-block lg:mr-10   text-sm text-white hover:text-gray-50  border-transparent border-b-2 uppercase hover:border-gray-600 cursor-pointer"
          @click="$router.push({ path: 'privacy' })"
        >
          Datenschutz
        </div>
        <div class=" lg:hidden">
          <div
            class="inline-block lg:mr-10   text-sm text-white hover:text-gray-50    uppercase   "
          >
            Copyright {{ year }}
          </div>
        </div>
      </div>
  
      <div class="hidden lg:block">
        <div
          class="inline-block mr-10 text-sm text-white hover:text-gray-50     uppercase    "
        >
          Copyright {{ year }}
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
import logo from "@/assets/Logo.svg";

export default {
  name: "Footer",
  setup() {
    return {
      logo,
    };
  },
  data: () => ({
    year: new Date().getFullYear(),
  }),
  methods: {
    openRoot() {
      this.$router.push({
        name: "Home",
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
