<template>
  <div>
    <Hero />
    <ContactForm />
  </div>
</template>

<script>
import Hero from "@/components/Contact/Hero";
import ContactForm from "@/components/Contact/ContactForm";

export default {
  name: "ContactView",
  components: { Hero, ContactForm },
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
